import GATSBY_COMPILED_MDX from "/home/runner/work/overfullstack.github.io/overfullstack.github.io/src/pages/articles/2016-10-26---kung-fu-lambda/index.md";
import {graphql} from "gatsby";
import React from "react";
import {Helmet} from "react-helmet";
import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import TagTemplateDetails from "../components/TagTemplateDetails";
const TagTemplate = props => {
  const {blogTitle} = props.data.site.siteMetadata;
  const {tag} = props.pageContext;
  return React.createElement("div", {
    style: {
      color: `var(--textNormal)`,
      background: `var(--bg)`,
      transition: `color 0.2s ease-out, background 0.2s ease-out`,
      minHeight: `100vh`
    }
  }, React.createElement(Layout, null, React.createElement("div", null, React.createElement(Helmet, {
    title: `All Posts tagged as "${tag}" | ${blogTitle}`
  }), React.createElement(Sidebar, props), React.createElement(TagTemplateDetails, props))));
};
TagTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(TagTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        blogTitle
        subtitle
        copyright
        declaration
        menu {
          label
          path
        }
        author {
          name
          email
          youtube
          twitter
          github
          linkedin
          stackoverflow
          aboutme
        }
      }
    }
    allMdx(
      limit: 1000
      filter: {
        frontmatter: {
          tags: { in: [$tag] }
          layout: { eq: "post" }
          draft: { ne: true }
        }
      }
      sort: { frontmatter: { date: DESC } }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`;
