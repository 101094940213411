/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    span: "span",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "Pure-Functions",
    style: {
      position: "relative"
    }
  }, "Pure Functions", React.createElement(_components.a, {
    href: "#Pure-Functions",
    "aria-label": "Pure Functions permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "These return a value based on what is passed into the function."), "\n", React.createElement(_components.li, null, "For instance, if we pass x into a function as input we will get F of x out as output."), "\n", React.createElement(_components.li, null, "Another property of a pure function is that it will always return the same result for the same passed in value."), "\n", React.createElement(_components.li, null, "If we pass input x into the function again, we should get back the exact same value, F of x. You should be able to do\nthis an infinite amount of times and always get back the same output for a specific input. In addition, pure functions\ndo not modify values outside of its scope. They are independent from any state in the system."), "\n", React.createElement(_components.li, null, "They never mutate data and they do not produce side effects. Generally, they can also easily be reused."), "\n"), "\n", React.createElement(_components.h2, {
    id: "First-Class-Functions",
    style: {
      position: "relative"
    }
  }, "First-Class Functions", React.createElement(_components.a, {
    href: "#First-Class-Functions",
    "aria-label": "First Class Functions permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "First, a programming language must support first-class functions to support the functional programming\nparadigm."), "\n", React.createElement(_components.li, null, "First-class functions can be stored as a variable, they can be passed as an argument, and also they can be returned as\nthe result of a function call."), "\n", React.createElement(_components.li, null, "In addition, a first-class function can also be bound to a variable name, as well as stored in a data structure.\nBasically, you can generally do anything with a first-class function that you would be able to do with a variable or\nobject."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Higher-order-Functions",
    style: {
      position: "relative"
    }
  }, "Higher-order Functions", React.createElement(_components.a, {
    href: "#Higher-order-Functions",
    "aria-label": "Higher order Functions permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "These are functions that can operate on other functions. They can take in a function as input, or even return one as\noutput."), "\n", React.createElement(_components.li, null, "First-class functions are useless without higher-order functions to pass them into or return them from."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Anonymous-Functions",
    style: {
      position: "relative"
    }
  }, "Anonymous Functions", React.createElement(_components.a, {
    href: "#Anonymous-Functions",
    "aria-label": "Anonymous Functions permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The roots of anonymous functions lie heavily in lambda calculus."), "\n", React.createElement(_components.li, null, "An anonymous function allows us to define ad hoc logic as we need, without needing to declare a formal named function."), "\n", React.createElement(_components.li, null, "They are nameless and they can also serve as an argument to another function."), "\n", React.createElement(_components.li, null, "Also, they can be assigned to a variable. They are basically a nameless first-class function."), "\n", React.createElement(_components.li, null, "Anonymous functions can be passed into or returned from a higher-order function."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Clousures",
    style: {
      position: "relative"
    }
  }, "Clousures", React.createElement(_components.a, {
    href: "#Clousures",
    "aria-label": "Clousures permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Closures are lexically scoped name binding in languages with first-class functions. Closures give us function\nportability. Closures are what allow us to pass around and store functions."), "\n", React.createElement(_components.li, null, "A closure is defined as a ", React.createElement(_components.strong, null, "persistent local variable scope"), " which holds on to local variables after the code\nexecution has moved out of that block."), "\n", React.createElement(_components.li, null, "Captured variables can be accessed through the closure’s copies of variable values or references when the function is\ninvoked outside of their scope. A closure, which is the scope of a function, is kept alive by a reference to that\nfunction. Variables which were in scope when that function was defined will still be in scope when we call it later in\na different context."), "\n", React.createElement(_components.li, null, "A closure can also be thought of as a record storing a function together with a environment. One final way of defining\na closure is as a mapping associating each variable of the function with the value or reference to which the name was\nbound when the closure was created. Next up, let’s go over functional composition."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Some-more",
    style: {
      position: "relative"
    }
  }, "Some-more", React.createElement(_components.a, {
    href: "#Some-more",
    "aria-label": "Some more permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Monad"), ", Think of monad as a context object that wraps a value and allows us to apply a set of transformations on\nthat value and get it back out with all the transformations applied."), "\n", React.createElement(_components.li, null, "Stream and Optional are Monads, when they are used as ", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">Stream.of()</code>"
    }
  }), " and ", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">Optional.of()</code>"
    }
  }), " as constructors and ", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">map()</code>"
    }
  }), "\nand ", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">flatMap()</code>"
    }
  }), " as binding functions."), "\n"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-code-title\">OptionalMonad.java</div>"
    }
  }), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"java\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-java line-numbers\"><code class=\"language-java\"><span class=\"token class-name\">Optional</span><span class=\"token punctuation\">.</span><span class=\"token function\">of</span><span class=\"token punctuation\">(</span><span class=\"token number\">5</span><span class=\"token punctuation\">)</span><span class=\"token punctuation\">.</span><span class=\"token function\">flatMap</span><span class=\"token punctuation\">(</span>addOne<span class=\"token punctuation\">)</span><span class=\"token punctuation\">.</span><span class=\"token function\">flatMap</span><span class=\"token punctuation\">(</span>addTwo<span class=\"token punctuation\">)</span><span class=\"token punctuation\">.</span><span class=\"token function\">equals</span><span class=\"token punctuation\">(</span><span class=\"token class-name\">Optional</span><span class=\"token punctuation\">.</span><span class=\"token function\">of</span><span class=\"token punctuation\">(</span><span class=\"token number\">5</span><span class=\"token punctuation\">)</span><span class=\"token punctuation\">.</span><span class=\"token function\">flatMap</span><span class=\"token punctuation\">(</span>addThree<span class=\"token punctuation\">)</span><span class=\"token punctuation\">)</span><span class=\"token punctuation\">;</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">collect()</code>"
    }
  }), " method is called ", React.createElement(_components.strong, null, "fold"), " in FP terms, which summarizes bunch of values into one."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
