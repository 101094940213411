/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    span: "span",
    h2: "h2",
    ul: "ul",
    li: "li",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "Creational-Patterns",
    style: {
      position: "relative"
    }
  }, "Creational Patterns", React.createElement(_components.a, {
    href: "#Creational-Patterns",
    "aria-label": "Creational Patterns permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "Factory-method-Vs-Abstract-Factory",
    style: {
      position: "relative"
    }
  }, "Factory method Vs Abstract Factory", React.createElement(_components.a, {
    href: "#Factory-method-Vs-Abstract-Factory",
    "aria-label": "Factory method Vs Abstract Factory permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Abstract factory has a level more depth of abstraction."), "\n", React.createElement(_components.li, null, "Factory method abstracts creation of a family of objects."), "\n", React.createElement(_components.li, null, "Abstract factory does the same, but inside, the product depends on an abstract factory which can be assigned any of the family of factories."), "\n", React.createElement(_components.li, null, "This abstract factory is ‘composed’ (HAS-A) inside factory."), "\n", React.createElement(_components.li, null, "Abstract Factory is one level higher in abstraction than Factory Method. Factory Method abstracts the way objects are created, while Abstract Factory abstracts the way factories are created which in turn abstracts the way objects are created."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Builder-Pattern",
    style: {
      position: "relative"
    }
  }, "Builder Pattern", React.createElement(_components.a, {
    href: "#Builder-Pattern",
    "aria-label": "Builder Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When the parameters involved in construction of an object are dynamic and inconsistent in terms of optional/required, passing null for optionals or having multiple constructors can get dirty."), "\n", React.createElement(_components.li, null, "Instead, a ‘builder’ class, which HAS-A the object to build, can be made responsible of creation."), "\n", React.createElement(_components.li, null, "This can be achieved by fluent methods inside builder class, which act like setter methods on the object to be created."), "\n"), "\n", React.createElement(_components.h1, {
    id: "Behavioral-Patterns",
    style: {
      position: "relative"
    }
  }, "Behavioral Patterns", React.createElement(_components.a, {
    href: "#Behavioral-Patterns",
    "aria-label": "Behavioral Patterns permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "Strategy-Pattern",
    style: {
      position: "relative"
    }
  }, "Strategy Pattern", React.createElement(_components.a, {
    href: "#Strategy-Pattern",
    "aria-label": "Strategy Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "“Encapsulate what varies”, use HAS-A over IS-A."), "\n", React.createElement(_components.li, null, "Behavior encapsulated into an object which is used HAS-A. This can be dynamically changed to change behavior of the object."), "\n", React.createElement(_components.li, null, "This is called Runtime Sub-classing or Runtime Polymorphism."), "\n", React.createElement(_components.li, null, "This is alternative to sub-classing"), "\n"), "\n", React.createElement(_components.h2, {
    id: "Observer-pattern",
    style: {
      position: "relative"
    }
  }, "Observer pattern", React.createElement(_components.a, {
    href: "#Observer-pattern",
    "aria-label": "Observer pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Observer objects are registered in a HAS-A fashion."), "\n", React.createElement(_components.li, null, "All observers implement same interface that contain the notify or callback method."), "\n", React.createElement(_components.li, null, "This encourages loose coupling."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Command-Pattern",
    style: {
      position: "relative"
    }
  }, "Command Pattern", React.createElement(_components.a, {
    href: "#Command-Pattern",
    "aria-label": "Command Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Encapsulates request or command as an object."), "\n", React.createElement(_components.li, null, "To decouple client from receiver Invocation, the request/command object is given to invoker, instead of directly invoking it on the receiver."), "\n", React.createElement(_components.li, null, "It helps in Queueing, logging requests etc."), "\n", React.createElement(_components.li, null, "Command object HAS-A receiver which calls methods on receiver. Client just has to call invoker.execute();"), "\n"), "\n", React.createElement(_components.h2, {
    id: "Template-Method-Pattern",
    style: {
      position: "relative"
    }
  }, "Template Method Pattern", React.createElement(_components.a, {
    href: "#Template-Method-Pattern",
    "aria-label": "Template Method Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "It defines the skeleton of algorithm, deferring some steps to sub-classes to define or redefine, without changing algorithm structure."), "\n", React.createElement(_components.li, null, "It can also contain methods called ‘Hooks’, to provide default implementation of a method common to all classes."), "\n", React.createElement(_components.li, null, "In template, client can depend on higher abstract class. It has control over algorithm and calls to subclasses only when there is a need to implement a method."), "\n", React.createElement(_components.li, null, "This is a great design tool for creating ", React.createElement(_components.em, null, "Frameworks"), "."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Iterator-Pattern",
    style: {
      position: "relative"
    }
  }, "Iterator Pattern", React.createElement(_components.a, {
    href: "#Iterator-Pattern",
    "aria-label": "Iterator Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Provides a way to iterate through a aggregate collection of objects."), "\n", React.createElement(_components.li, null, "The Iterator object consists of methods that takes care of looping through the collection."), "\n", React.createElement(_components.li, null, "Underlying representation of collection (be it array or arrayList or HashMap) is hidden from client."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Composite-Pattern",
    style: {
      position: "relative"
    }
  }, "Composite Pattern", React.createElement(_components.a, {
    href: "#Composite-Pattern",
    "aria-label": "Composite Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "It deals with 2 responsibilities: ", React.createElement(_components.em, null, "Iterating"), " and ", React.createElement(_components.em, null, "Uniformity"), "."), "\n", React.createElement(_components.li, null, "Allows objects to compose objects into tree structure to represent ", React.createElement(_components.em, null, "Part-Whole"), " hierarchies."), "\n", React.createElement(_components.li, null, "It lets client treat both individual objects and composite objects the same way."), "\n", React.createElement(_components.li, null, "SRP (Single Responsibility Principle) is violated for Transparency."), "\n"), "\n", React.createElement(_components.h2, {
    id: "State-Pattern",
    style: {
      position: "relative"
    }
  }, "State Pattern", React.createElement(_components.a, {
    href: "#State-Pattern",
    "aria-label": "State Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The behavior of an object depends on its state."), "\n", React.createElement(_components.li, null, "State is controlled and switched using a HAS-A object."), "\n", React.createElement(_components.li, null, "It smells like ", React.createElement(_components.em, null, "Strategy Pattern"), " but they differ in intent."), "\n", React.createElement(_components.li, null, "Strategy pattern is an alternate for Sub-Classing while State pattern is an alternative to having if-else conditions everywhere and encapsulating behavior to state object."), "\n"), "\n", React.createElement(_components.h1, {
    id: "Structural-Patterns",
    style: {
      position: "relative"
    }
  }, "Structural Patterns", React.createElement(_components.a, {
    href: "#Structural-Patterns",
    "aria-label": "Structural Patterns permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "Decorator-Pattern",
    style: {
      position: "relative"
    }
  }, "Decorator Pattern", React.createElement(_components.a, {
    href: "#Decorator-Pattern",
    "aria-label": "Decorator Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "“Open-Closed” Principle. Open for extension and closed for change."), "\n", React.createElement(_components.li, null, "This is used to achieve “Dynamic Inheritance”."), "\n", React.createElement(_components.li, null, "A component is given to Decorator object as HAS-A and Decorator class adds extra functionality or responsibility to it."), "\n", React.createElement(_components.li, null, "Both component and Decorator implement the same interface. So one decorator can be component to another decorator, thus behavior can be added in layers or wrappers."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Adapter-Pattern",
    style: {
      position: "relative"
    }
  }, "Adapter Pattern", React.createElement(_components.a, {
    href: "#Adapter-Pattern",
    "aria-label": "Adapter Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Converts one interface to another (Target), adaptable to client."), "\n", React.createElement(_components.li, null, "It is done by implementing ‘Target’ interface and HAS-A Adoptee object. Now client calls methods on Adapter and it delegates appropriately to Adoptee."), "\n", React.createElement(_components.li, null, "Decorator, Adapter and Facade patterns are close but differ in their intent."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Bridge-Pattern",
    style: {
      position: "relative"
    }
  }, "Bridge Pattern", React.createElement(_components.a, {
    href: "#Bridge-Pattern",
    "aria-label": "Bridge Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "This is similar to Adapter pattern, except that it bridges between two hierachies of abstractions."), "\n", React.createElement(_components.li, null, "It has two layers abstractions, one that holds the interface methods for client and the other abstraction is what actual implementations extend."), "\n", React.createElement(_components.li, null, "Abstraction for client HAS-A implementor abstraction for delegation."), "\n", React.createElement(_components.li, null, "This provides flexibility for both these hierarchies to independently change."), "\n", React.createElement(_components.li, null, "This has downside of increasing complexity."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Facade-Pattern",
    style: {
      position: "relative"
    }
  }, "Facade Pattern", React.createElement(_components.a, {
    href: "#Facade-Pattern",
    "aria-label": "Facade Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "It’s an unified interface, that a client can use with ease to control many other sub-systems."), "\n", React.createElement(_components.li, null, "A facade HAS-A all other components of sub-systems references and makes use of them appropriately."), "\n", React.createElement(_components.li, null, "If there are too many sub-systems for one Facade to handle, we can multiple layers of Facade."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Proxy-Pattern",
    style: {
      position: "relative"
    }
  }, "Proxy Pattern", React.createElement(_components.a, {
    href: "#Proxy-Pattern",
    "aria-label": "Proxy Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "It provides a surrogate or placeholder for another object to control access to it."), "\n", React.createElement(_components.li, null, "The local stub has the same API as original object. Once client invokes any method on stub, it requests the state of original object and provides it to the client."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Decorator-vs-Adapter-vs-Facade",
    style: {
      position: "relative"
    }
  }, "Decorator vs Adapter vs Facade", React.createElement(_components.a, {
    href: "#Decorator-vs-Adapter-vs-Facade",
    "aria-label": "Decorator vs Adapter vs Facade permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Decorator: Adds responsibility without the change of interface."), "\n", React.createElement(_components.li, null, "Adapter: Converts on interface to another."), "\n", React.createElement(_components.li, null, "Facade: Simplify an interface."), "\n"), "\n", React.createElement(_components.h1, {
    id: "Bonus",
    style: {
      position: "relative"
    }
  }, "Bonus", React.createElement(_components.a, {
    href: "#Bonus",
    "aria-label": "Bonus permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "Principle-of-Least-knowledge-or-Law-of-Delimiter",
    style: {
      position: "relative"
    }
  }, "Principle of Least knowledge (or) Law of Delimiter", React.createElement(_components.a, {
    href: "#Principle-of-Least-knowledge-or-Law-of-Delimiter",
    "aria-label": "Principle of Least knowledge or Law of Delimiter permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "One should make calls only to its immediate objects. Otherwise, it builds unnecessary dependencies between objects and makes the system fragile and complex to understand."), "\n"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-code-title\">lawofdelimiter.java</div>"
    }
  }), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"java\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-java line-numbers\"><code class=\"language-java\">station<span class=\"token punctuation\">.</span><span class=\"token function\">getThermometer</span><span class=\"token punctuation\">(</span><span class=\"token punctuation\">)</span><span class=\"token punctuation\">.</span><span class=\"token function\">getTemp</span><span class=\"token punctuation\">(</span><span class=\"token punctuation\">)</span> <span class=\"token comment\">// Wrong approach</span>\nstation<span class=\"token punctuation\">.</span><span class=\"token function\">getTemp</span><span class=\"token punctuation\">(</span><span class=\"token punctuation\">)</span> <span class=\"token comment\">// Right approach</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "But we can call methods on objects we pass or create in any local methods."), "\n", React.createElement(_components.li, null, "However, this results in more ‘wrapper’ classes being written."), "\n", React.createElement(_components.li, null, "The same is followed in Facade pattern, client only interacts with its immediate Facade object."), "\n"), "\n", React.createElement(_components.h2, {
    id: "Hollywood-Principle",
    style: {
      position: "relative"
    }
  }, "Hollywood Principle", React.createElement(_components.a, {
    href: "#Hollywood-Principle",
    "aria-label": "Hollywood Principle permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Always higher level components should call lower-level ones."), "\n"), "\n", React.createElement(_components.h2, {
    id: "MVC-Pattern",
    style: {
      position: "relative"
    }
  }, "MVC Pattern", React.createElement(_components.a, {
    href: "#MVC-Pattern",
    "aria-label": "MVC Pattern permalink",
    className: "anchor after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "View and Controller together implement ", React.createElement(_components.em, null, "Strategy Pattern"), "."), "\n", React.createElement(_components.li, null, "View depends on controller for user actions, so view behavior depends on Controller. Now, this controller can be easily replaced with another to change behavior."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
