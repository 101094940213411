/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    span: "span"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<div>\n        <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://bit.ly/konfy-interview\" class=\"link-card-container\">\n            <div class=\"link-card-wrapper\">\n                <div class=\"link-card-text\">\n                    <div class=\"link-card-title\">\"I'm a Gradle guy\", — Gopal S Akshintala - DEV Community</div>\n                    <div class=\"link-card-description\">Hello world!   We are counting days before the jLove, and I am glad to introduce to you our next...</div>\n                </div>\n                <div class=\"link-card-url\">\n                    <img class=\"link-card-favicon\" src=\"https://res.cloudinary.com/practicaldev/image/fetch/s--E8ak4Hr1--/c_limit,f_auto,fl_progressive,q_auto,w_32/https://dev-to.s3.us-east-2.amazonaws.com/favicon.ico\" alt=\"\" i'm=\"\" a=\"\" gradle=\"\" guy\",=\"\" —=\"\" gopal=\"\" s=\"\" akshintala=\"\" -=\"\" dev=\"\" community-favicon\"=\"\">\n                    <div class=\"link-card-link\">https://bit.ly/konfy-interview</div>\n                </div>\n            </div>\n            <div class=\"link-card-image-wrapper\">\n                            <img class=\"link-card-image\" alt=\"\" i'm=\"\" a=\"\" gradle=\"\" guy\",=\"\" —=\"\" gopal=\"\" s=\"\" akshintala=\"\" -=\"\" dev=\"\" community-image\"=\"\" src=\"https://dev.to/social_previews/article/725403.png\">\n                        </div>\n        </a>\n    </div>"
    }
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
