import GATSBY_COMPILED_MDX from "/home/runner/work/overfullstack.github.io/overfullstack.github.io/src/pages/articles/2016-10-26---kung-fu-lambda/index.md";
import "katex/dist/katex.min.css";
import {graphql} from "gatsby";
import React from "react";
import {Helmet} from "react-helmet";
import Layout from "../components/Layout";
import PostTemplateDetails from "../components/PostTemplateDetails";
import SEO from "../components/SEO";
const PostTemplate = props => {
  const {subtitle} = props.data.site.siteMetadata;
  const post = props.data.mdx;
  const {title: postTitle, description: postDescription, cover} = post.frontmatter;
  const description = postDescription !== null ? postDescription : subtitle;
  const actualPostTitle = `${postTitle}`;
  return React.createElement("div", {
    style: {
      color: `var(--textNormal)`,
      background: `var(--bg)`,
      transition: `color 0.2s ease-out, background 0.2s ease-out`,
      minHeight: `100vh`
    }
  }, React.createElement(Layout, null, React.createElement(SEO, {
    title: actualPostTitle,
    description: description,
    cover: cover ? cover.absolutePath : ``,
    slug: post.fields.slug
  }), React.createElement("div", null, React.createElement(Helmet, null, React.createElement("title", null, actualPostTitle), React.createElement("meta", {
    name: "description",
    content: description
  })), React.createElement(PostTemplateDetails, props))));
};
PostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        blogTitle
        subtitle
        copyright
        declaration
        author {
          name
          twitter
          github
          linkedin
          youtube
          email
          stackoverflow
          aboutme
          resume
        }
        disqusShortname
        url
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      fields {
        tagSlugs
        slug
      }
      frontmatter {
        title
        tags
        date
        description
        cover {
          absolutePath
        }
      }
    }
  }
`;
