import GATSBY_COMPILED_MDX from "/home/runner/work/overfullstack.github.io/overfullstack.github.io/src/pages/pages/2015-05-01---contact-me/index.md";
import {graphql} from "gatsby";
import React from "react";
import {Helmet} from "react-helmet";
import Layout from "../components/Layout";
import PageTemplateDetails from "../components/PageTemplateDetails";
import SEO from "../components/SEO";
const PageTemplate = props => {
  const {subtitle} = props.data.site.siteMetadata;
  const page = props.data.mdx;
  const {title: pageTitle, description: pageDescription, cover} = page.frontmatter;
  const description = pageDescription !== null ? pageDescription : subtitle;
  const actualPageTitle = `${pageTitle}`;
  return React.createElement("div", {
    style: {
      color: `var(--textNormal)`,
      background: `var(--bg)`,
      transition: `color 0.2s ease-out, background 0.2s ease-out`,
      minHeight: `100vh`
    }
  }, React.createElement(Layout, null, React.createElement(SEO, {
    title: actualPageTitle,
    description: description,
    cover: cover ? cover.absolutePath : ``,
    slug: page.fields.slug
  }), React.createElement("div", null, React.createElement(Helmet, null, React.createElement("title", null, `${pageTitle}`), React.createElement("meta", {
    name: "description",
    content: description
  })), React.createElement(PageTemplateDetails, props))));
};
PageTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        subtitle
        copyright
        declaration
        menu {
          label
          path
        }
        author {
          name
          email
          youtube
          twitter
          github
          linkedin
          stackoverflow
          aboutme
        }
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
        date
        description
        cover {
          absolutePath
        }
      }
    }
  }
`;
